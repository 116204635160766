<template>
  <div>
      <div>
           <nav class="headBo">
        <b-navbar toggleable="lg" type="dark" style=" background: #4b4d4e;">
          <b-navbar-brand href="/"
            ><img
              src="../assets/SimplifyLogo.png"
              alt="Simplify CV"
              height="50"
              width="200"
          /></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav> </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="#" disabled>
                <b-button variant="link" style="color: #ffffff;"
                  >Contact Us</b-button
                ></b-nav-item
              >

              <b-nav-item href="#" disabled>
                <b-button variant="link" style="color: #ffffff;"
                  >FAQ's</b-button
                ></b-nav-item
              >
              <b-nav-item href="#">
                <b-button
                  variant="danger"
                  class="signInBot"
                  style="width: 120px !important;height:40px !important"
                  ><b>Sign In</b></b-button
                ></b-nav-item
              >
              <b-nav-item href="#">
                <b-button variant="light" class="signInBot">
                  <b>Logout</b></b-button
                ></b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </nav>
      </div>
      <div class="d-flex justify-content-around success-main">
          <div class="success-left">
              <div class="finish-para">Successful Submited</div>
              <div class="sub-finish-para1 mt-3">Your report card is ready!</div>
              <div class="sub-finish-para">Click on button below to see the report card</div>
              <b-button variant="outline" class="reportcard-btn mt-3">See Report Card</b-button>
          </div>
          <div class="success-right"><img src="../assets/successful.png" class="success-img" alt=""></div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>
